import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import { Navbar, Nav, NavDropdown, Container } from 'react-bootstrap';

const Header = ({ siteTitle, menuLinks }) => (
  <Navbar bg="dark" variant="dark" expand="lg" sticky="top">
    <Container>
      <Navbar.Brand>
        <Link to="/"
          style={{
            color: `white`,
            textDecoration: `none`,
          }}>
          {siteTitle}
        </Link>
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="mr-auto">
          {menuLinks.map(menuLink => (
            menuLink.list.length > 0 ?
              <NavDropdown key={menuLink.name} id="basic-nav-dropdown" title={menuLink.icon + " " + menuLink.name}>
                {menuLink.list.map(sublink => (
                  <NavDropdown.Item key={sublink.name} as={Link} to={sublink.link}>{sublink.icon} {sublink.name}</NavDropdown.Item>
                ))}
              </NavDropdown> : <Link to={menuLink.link} className="nav-link" role="button" type="button">{menuLink.icon + " " + menuLink.name}</Link>
          ))}
        </Nav>
      </Navbar.Collapse>
    </Container>
  </Navbar>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
