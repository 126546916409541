import React from "react"

import { Container } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTwitter, faFacebook, faYoutube, faInstagram } from '@fortawesome/free-brands-svg-icons'
// The following import prevents a Font Awesome icon server-side rendering bug,
// where the icons flash from a very large icon down to a properly sized one:
import '@fortawesome/fontawesome-svg-core/styles.css';
// Prevent fontawesome from adding its CSS since we did it manually above:
import { config } from '@fortawesome/fontawesome-svg-core';

import { openInNewTab } from "../utils/utils"

config.autoAddCss = false;

const Footer = () => (
    <Container>
        <div style={{ textAlign: "center" }}>
            <h4>Follow us on social media:</h4>
            <SocialLink link="https://twitter.com/mxtgames" icon={faTwitter} />
            <SocialLink link="https://www.facebook.com/mxtgames" icon={faFacebook} />
            <SocialLink link="https://www.youtube.com/mxtgames" icon={faYoutube} />
            <SocialLink link="https://www.instagram.com/mxtgames" icon={faInstagram} />
            <br />
            <hr />
    Copyright © 2014 - {new Date().getFullYear()} MXT Games. All Rights Reserved.
        Icons by <a href="https://fontawesome.com/license" target="_blank" rel="noreferrer"> Font Awsome</a>
        </div>
    </Container>
)

const SocialLink = (props) => {
    const link = props.link
    const icon = props.icon
    return (
        <FontAwesomeIcon
            icon={icon} size="2x"
            style={{ marginLeft: 8, marginRight: 8, cursor: 'pointer' }}
            onClick={() => {
                openInNewTab(link)
            }} />
    )
}

export default Footer